<template>
	<div id="backend">
		<header>
			<div class="container-wide flex space-between align-center">
				<div class="flex align-center">
					<router-link to="/">
						<img
							src="@/assets/terminal.svg"
							class="mb-10 mr-30"
							alt=""
						/>
					</router-link>

					<router-link
						class="mx-15 hover:color-blue-700"
						:class="{ 'color-blue': isActiveLink('Dashboard'), 'color-grey': !isActiveLink('Dashboard') }"
						:to="{ name: 'Dashboard' }"
						>Kunningarbretti</router-link
					>

					<!-- <router-link class="mx-15 hover:color-blue-700" :class="{ 'color-blue': isActiveLink('Agreement'), 'color-grey': !isActiveLink('Agreement') }" :to="{ name: 'Agreements' }">Sáttmálar</router-link> -->

					<router-link
						class="mx-15 hover:color-blue-700"
						:class="{ 'color-blue': isActiveLink('Customer'), 'color-grey': !isActiveLink('Customer') }"
						:to="{ name: 'Customers' }"
						>Kundar</router-link
					>

					<router-link
						class="mx-15 hover:color-blue-700"
						:class="{ 'color-blue': isActiveLink('Terminal'), 'color-grey': !isActiveLink('Terminal') }"
						:to="{ name: 'Terminals' }"
						>Terminalar</router-link
					>

					<router-link
						class="mx-15 hover:color-blue-700"
						:class="{ 'color-blue': isActiveLink('Transaction'), 'color-grey': !isActiveLink('Transaction') }"
						:to="{ name: 'Transactions' }"
						>Uppgerðir</router-link
					>

					<router-link
						class="mx-15 hover:color-blue-700"
						:class="{ 'color-blue': isActiveLink('KYC'), 'color-grey': !isActiveLink('KYC') }"
						:to="{ name: 'KYC' }"
						>KYC</router-link
					>

					<router-link
						class="mx-15 hover:color-blue-700"
						:class="{ 'color-blue': isActiveLink('Support'), 'color-grey': !isActiveLink('Support') }"
						:to="{ name: 'Support' }"
						>Support</router-link
					>
				</div>

				<div class="dropdown-container">
					<div class="relative">
						<a
							href="#"
							@click.prevent="dropdown = !dropdown"
							class="initials"
							:class="{ small: initials == 'terminal' }"
							>{{ initials }}</a
						>

						<i
							class="fas fa-chevron-down"
							:class="{ active: dropdown }"
						></i>
					</div>

					<div
						class="dropdown"
						:class="{ active: dropdown }"
						style="min-width: 200px"
					>
						<router-link
							class="hover:color-blue-700"
							:class="{ 'color-blue': isActiveLink('Messages'), 'color-grey': !isActiveLink('Messages') }"
							:to="{ name: 'Messages' }"
						>
							<i class="fas fa-sms"></i>

							Boð
						</router-link>

						<router-link
							class="hover:color-blue-700"
							:class="{ 'color-blue': isActiveLink('Step'), 'color-grey': !isActiveLink('Step') }"
							:to="{ name: 'StepLists' }"
						>
							<i class="fas fa-list"></i>

							Leistir
						</router-link>

						<router-link
							class="hover:color-blue-700"
							:class="{ 'color-blue': isActiveLink('Action'), 'color-grey': !isActiveLink('Action') }"
							:to="{ name: 'Actions' }"
						>
							<i class="fas fa-envelope"></i>

							Teldupostar
						</router-link>

						<router-link
							class="hover:color-blue-700"
							:class="{ 'color-blue': isActiveLink('User'), 'color-grey': !isActiveLink('User') }"
							:to="{ name: 'Users' }"
						>
							<i class="fas fa-users"></i>

							Umsitarar
						</router-link>

						<hr />

						<a
							href="#"
							class="color-grey logout hover:color-red"
							@click.prevent="logout()"
						>
							<i class="fas fa-sign-out-alt"></i>

							Rita út
						</a>
					</div>
				</div>
			</div>
		</header>

		<router-view :key="$route.fullPath"></router-view>
	</div>
</template>

<script>
export default {
	name: 'AdminLayout',

	data() {
		return {
			initials: '',
			dropdown: false,
		};
	},

	mounted() {
		window.addEventListener('click', (e) => {
			if (!e.target.classList.contains('initials')) {
				this.dropdown = false;
			}
		});

		const localStorageUser = localStorage.getItem('user');

		if (!localStorageUser) {
			return this.$router.push({ name: 'Login' });
		}

		const { firstName, lastName, twoFactorVerifiedAt } = JSON.parse(localStorageUser);

		if (!twoFactorVerifiedAt) {
			if (!sessionStorage.getItem('two-factor-wait')) {
				return this.$router.push({ name: 'TwoFactor' });
			}
		}

		if (!firstName && !lastName) {
			this.initials = 'terminal';

			return;
		}

		this.initials = `${firstName[0]}${lastName[0]}`;
	},

	methods: {
		logout() {
			localStorage.setItem('access_token', '');

			localStorage.clear();

			this.$router.push({ name: 'Login' });
		},

		isActiveLink(link) {
			return this.$route.name.includes(link);
		},
	},
};
</script>

<style lang="scss" scoped>
header {
	padding: 20px 0;
}

.initials {
	display: grid;
	place-items: center;

	width: 42px;
	height: 42px;

	color: var(--color-white);
	background: var(--color-blue);

	border-radius: 50%;

	font-weight: 700;

	transition: 0.15s background-color ease-in-out;

	&:hover {
		background: var(--color-blue-600);
	}

	&.small {
		font-weight: 700;
		font-size: 10px;
	}
}

.dropdown-container {
	position: relative;

	.fa-chevron-down {
		position: absolute;
		bottom: -16px;
		left: 50%;
		transform: translateX(-50%);

		color: #ababab;

		transition: 0.2s transform ease-in-out;

		&.active {
			transform: translateX(-50%) rotate(180deg);
		}
	}
}

.dropdown {
	position: absolute;
	top: 100%;
	right: 0;

	display: flex;
	flex-direction: column;
	text-align: left;

	margin-top: 4px;
	padding: 8px 0;

	background: var(--color-white);

	border: 1px solid #e8e8e8;
	border-radius: 4px;

	box-shadow: 0 2px 4px rgba(black, 0.05);

	transform: translateY(-8px);
	opacity: 0;
	pointer-events: none;

	transition: 0.25s all ease-in-out;

	&.active {
		transform: translateY(0);
		opacity: 1;
		pointer-events: all;
	}

	a {
		padding: 10px 40px 10px 12px;

		i.fas {
			width: 20px;
		}

		&:hover {
			background: rgba(black, 0.05);
		}
	}

	hr {
		width: 100%;

		border-top: 1px solid #f2f2f2;
		margin: 4px 0;
	}

	.logout {
		padding: 10px 40px 10px 12px;
	}
}
</style>
